<!-- 会员设置 -->
<template>
  <div>
    <div class="member-area">
      <normal-card>
        <div class="total">
          会员卡总销量：
          <span style="color: #f00; font-size: 20px;"> {{ statInfo.total }} </span> 张
          <span style="color: #f00; font-size: 20px; margin-left: 5px;"> {{ statInfo.salesAmount }} </span> 元
        </div>
        <div class="line"></div>
        <div class="member-number">
          <div class="gold-num">
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/282-huangjin.png" />
            <div class="number">
              <span style="font-weight: 520;">{{ statInfo.gold.count }}</span> 张
              ￥<span style="color: #f00; font-weight: 520;">{{ statInfo.gold.totalAmount }}</span>
            </div>
            <div class="gold-title">黄金会员卡</div>
          </div>
          <div class="silver-num">
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/283-baiying.png" />
            <div class="number">
              <span style="font-weight: 520;">{{ statInfo.silver.count }}</span> 张
              ￥<span style="color: #f00; font-weight: 520;">{{ statInfo.silver.totalAmount }}</span>
            </div>
            <div class="silver-title">白银会员卡</div>
          </div>
          <div class="bronze-num">
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/284-qingtong.png" />
            <div class="number">
              <span style="font-weight: 520;">{{ statInfo.bronze.count }}</span> 张
              ￥<span style="color: #f00; font-weight: 520;">{{ statInfo.bronze.totalAmount }}</span>
            </div>
            <div class="bronze-title">青铜会员卡</div>
          </div>
        </div>
      </normal-card>
    </div>
    <div class="member-card">
      <normal-card>
        <div class="card-title">
          <div>会员卡设置</div>
          <el-button type="primary" icon="el-icon-plus" @click="addCard">新增会员卡</el-button>
        </div>
        <div class="card-area">
          <div v-for="(item, index) in cardLists" :key="index">
            <div class="gold" v-if="item.couponType == 30">
              <div class="card"
                style="background-image: url('https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/398-huangjin.jpg'); background-size: 100% 100%;">
                <div class="fieldName">{{ fieldName }}</div>
                <div class="gold-discount">￥{{ item.price }}</div>
                <div class="amount">
                  面值 <span style="font-size: 12px;">{{ item.total }}元</span>
                  （赠送 <span style="font-size: 12px; color: #f00;">{{ item.bonus }}元</span>）
                </div>
              </div>
              <div class="delete" @click="deleteCard(item)">删 除</div>
            </div>
            <div class="silver" v-if="item.couponType == 20">
              <div class="card"
                style="background-image: url('https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/397-baiyin.jpg'); background-size: 100% 100%;">
                <div class="fieldName">{{ fieldName }}</div>
                <div class="silver-discount">￥{{ item.price }}</div>
                <div class="amount">
                  面值 <span style="font-size: 12px;">{{ item.total }}元</span>
                  （赠送 <span style="font-size: 12px; color: #f00;">{{ item.bonus }}元</span>）
                </div>
              </div>
              <div class="delete" @click="deleteCard(item)">删 除</div>
            </div>
            <div class="bronze" v-if="item.couponType == 10">
              <div class="card"
                style="background-image: url('https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/399-qingtong.jpg'); background-size: 100% 100%;">
                <div class="fieldName">{{ fieldName }}</div>
                <div class="bronze-discount">￥{{ item.price }}</div>
                <div class="amount">
                  面值 <span style="font-size: 12px;">{{ item.total }}元</span>
                  （赠送 <span style="font-size: 12px; color: #f00;">{{ item.bonus }}元</span>）
                </div>
              </div>
              <div class="delete" @click="deleteCard(item)">删 除</div>
            </div>
          </div>
        </div>
      </normal-card>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="创建会员卡" width="30%" @close="closeDialog">
      <el-form label-width="130px" ref="form" :model="formData" :rules="rules">
        <el-form-item label="会员等级：" prop="couponType">
          <el-select size="small" clearable v-model="formData.couponType" @change="changeType">
            <el-option v-for="item of cardTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值金额：" prop="price">
          <el-input-number size="small" style="width:192px" v-model="formData.price" :precision="2" :step="1"
            :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="赠送金额：" prop="bonus">
          <el-input-number size="small" style="width:192px" v-model="formData.bonus" :precision="2" :step="1"
            :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="卡片面值：">
          <el-input-number size="small" style="width:192px" v-model="total" :step="0.1" disabled></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button style="color: #134f7a;" @click="conmitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NormalCard from '../../components/NormalCard.vue';
export default {
  components: { NormalCard },
  data() {
    return {
      cardTypes: [
        { label: '黄金会员卡', value: 30 },
        { label: '白银会员卡', value: 20 },
        { label: '青铜会员卡', value: 10 }
      ],

      couponStatList: [],
      statInfo: {
        gold: {
          count: 0,
          totalAmount: 0
        },
        silver: {
          count: 0,
          totalAmount: 0
        },
        bronze: {
          count: 0,
          totalAmount: 0
        },
        normal: {
          count: 0,
          totalAmount: 0
        },
        total: 0,
        amount: 0,
        salesAmount: 0,
      },

      fieldName: '',
      cardLists: [],
      dialogVisible: false,
      formData: {
        couponType: '',
        couponName: '',
        price: 0,
        bonus: 0,
        total: 0
      },
      rules: {
        couponType: [{ message: '请选择会员等级', required: true, trigger: 'change' }],
        price: [
          { pattern: /^([1-9]\d*)(\.\d{1,6})?$|^0\.\d{1,6}?$/, message: '充值金额需大于0元', trigger: 'change' },
          { message: '请输入充值金额', required: true, trigger: 'change' }
        ],
        bonus: [
          { pattern: /^([1-9]\d*)(\.\d{1,6})?$|^0\.\d{1,6}?$/, message: '赠送金额需大于0元', trigger: 'change' },
          { message: '请输入赠送金额', required: true, trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    total() {
      return this.formData.price + this.formData.bonus;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.getFieldInfo();
      this.getCouponStat();
      this.getVipCardList();
    },
    // 获取球场信息
    async getFieldInfo() {
      const { res } = await this.$http.get('/Field/GetFieldInfo');
      this.fieldName = res.data.fieldName;
    },
    // 统计会员卡销量等信息
    async getCouponStat() {
      const { res } = await this.$http.get('/Vip/CouponStat');
      if (res.isSuccess) {
        this.couponStatList = res.data || [];

        this.statInfo.amount = 0;
        this.statInfo.total = 0;
        this.statInfo.salesAmount = 0;

        this.couponStatList.map(el => {
          this.statInfo.amount += el.totalAmount;
          this.statInfo.salesAmount = this.statInfo.amount.toFixed(2);
          this.statInfo.total += el.count;

          if (el.couponType == 10) {
            this.statInfo.bronze.count = el.count;
            this.statInfo.bronze.totalAmount = el.totalAmount;
          }
          if (el.couponType == 20) {
            this.statInfo.silver.count = el.count;
            this.statInfo.silver.totalAmount = el.totalAmount;
          }
          if (el.couponType == 30) {
            this.statInfo.gold.count = el.count;
            this.statInfo.gold.totalAmount = el.totalAmount;
          }
        })
      }
    },
    // 获取会员卡列表
    async getVipCardList() {
      const { res } = await this.$http.get('/Vip/FieldCounpon');
      if (res.isSuccess) {
        this.cardLists = res.data || [];
        this.cardLists.map(el => {
          el.total = el.price + el.bonus;
        })
      }
    },
    deleteCard(item) {
      this.$confirm("确定要删除该会员卡吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "confirm",
      }).then(async () => {
        const { res } = await this.$http.post(`/Vip/RemoveCoupon/${item.couponId}`);
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.getVipCardList();
        }
      })
    },
    addCard() {
      this.dialogVisible = true
    },
    closeDialog() {
      this.formData = {
        couponType: '',
        couponName: '',
        price: 0,
        bonus: 0,
        total: 0
      }
    },
    changeType(type) {
      if (type) {
        this.formData.couponName = this.cardTypes.find(el => {
          return el.value === type
        }).label;
      } else {
        this.formData.couponName = '';
      }
    },
    conmitData() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm("确定要新增该会员卡吗", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "confirm",
          }).then(async () => {
            const { res } = await this.$http.post('/Vip/AddCoupon', this.formData);
            if (res.isSuccess) {
              this.$showSuccess('保存成功');
              this.dialogVisible = false;
              this.getVipCardList();
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//会员卡总销量区域
.member-area {
  margin-top: 20px;
  padding: 20px 0;
  border-radius: 10px;
  color: #fff;
}

.total {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 550;
}

.line {
  background-color: #b1b1b1;
  width: 90%;
  height: 1px;
  margin: 10px auto;
}

.member-number {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-around;
}

.gold-num img,
.silver-num img,
.bronze-num img,
.ordinary-num img {
  width: 80px;
  height: 80px;
}

.member-number .gold-title,
.member-number .silver-title,
.member-number .bronze-title {
  margin: 10px auto;
  font-size: 13px;
}

.gold-num .number,
.silver-num .number,
.bronze-num .number,
.ordinary-num .number {
  margin: 10px auto;
  font-size: 17px;
  color: #000;
}

// 会员卡卡片
.member-card {
  margin-top: 20px;
  padding: 20px 0;
  border-radius: 10px;
}

.card-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 550;
  margin: 0 10px;
  color: #fff;
}

.addCard {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: #134f7a;
  text-align: center;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.card-area {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 20px;
}

.gold,
.silver,
.bronze {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.card {
  position: relative;
  width: 330px;
  height: 180px;
  margin: 10px;
}

.card .fieldName {
  width: 240px;
  position: absolute;
  top: 26px;
  left: 32px;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

.card .amount {
  position: absolute;
  top: 120px;
  left: 40px;
  font-size: 13px;
  color: #646464;
  background-color: rgba(218, 216, 216, 0.6);
}

.card .gold-discount,
.card .silver-discount,
.card .bronze-discount {
  position: absolute;
  top: 80px;
  left: 80px;
  font-size: 20px;
  font-weight: 500;
}

.delete {
  width: 60px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  background-color: #134f7a;
  text-align: center;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}
</style>
